<template>
  <div class="mainpage">
    <div class="head">
      <div class="head-title">线上课成绩</div>
      <div class="bt" @click="toback">返回</div>
    </div>
    <div class="line"></div>
    <div class="maincontain">
      <div class="returnbt">
        <div class="lefttitle">
          <el-radio v-model="radio" label="1" @change="radiobt">学习进度</el-radio>
          <el-radio v-model="radio" label="2" @change="radiobt">作业考试成绩</el-radio>
        </div>
      </div>
      <component :is="componentId" ref="customcomponent" :item="item"></component>
    </div>
  </div>
</template>

<script>
import Learnprogress from "@/views/wisdompracticeteaching/components/courselearning/components/courseoutline.vue"
import Testscore from "./testscore.vue"
import Customdialog from "@/components/customdialog.vue"

export default {
  name: "mainpage",
  components: {
    Customdialog,
    Learnprogress,
    Testscore,
  },
  data () {
    return {
      componentId: "Learnprogress",
      radio: "1",
      searchvalue: "",
      testimage: require("@/assets/test.png"),
      value: "",
      item: '',
    }
  },
  computed: {},
  created () {
    this.item = this.$route.query
    //console.log(this.item)
  },
  methods: {
    radiobt (value) {
      switch (value) {
        case "1":
          this.componentId = "Learnprogress"
          break
        case "2":
          this.componentId = "Testscore"
          break
      }
    },
    toback () {
      this.$router.push({
        path: "/home/personalcenter/mainpage",
        query: {
          id: 1,
          componentId: "Mycourses",
        },
      })
    },
    todetail () {
      this.$router.push({
        path: "/personalcenter/interactiveqadetails",
      })
    },
  },
};
</script>
<style scoped lang="scss">
.mainpage {
  background-color: #fff;
  min-height: calc(100vh - 240px);
  .head {
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-left: 20px;
    padding-top: 10px;
    height: 40px;
    .head-title {
      font-size: 20px;
      font-weight: 400;
      color: #3d84ff;
      user-select: none;
      white-space: nowrap;
    }
    .elinput {
      width: 300px;
      ::v-deep .el-input__suffix {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
  .line {
    margin-top: 14px;
    margin-bottom: 4px;
    border-bottom: 1px solid #cccccc;
  }
  .maincontain {
    border-radius: 4px;
    padding: 20px;
    margin-bottom: 20px;
    .returnbt {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .lefttitle {
        font-size: 20px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #222222;
      }
    }
  }
  .bt {
    margin-right: 40px;
    width: 64px;
    height: 38px;
    line-height: 38px;
    text-align: center;
    background: #3d84ff;
    border-radius: 4px;
    color: white;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    cursor: pointer;
    font-size: 14px;
  }
}
</style>
